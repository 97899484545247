<template>
	<li
		:class="{ 'text-color-text': styling === 'offline' }"
		class="relative post-link flex pt-6 pb-4 px-3 z-0 justify-between"
	>
		<template v-if="minify">
			<div
				class="w-full text-color-text-lighter"
				:class="{ 'flex flex-col md:flex-row ': !locationOnly }"
			>
				<div class="w-full mb-0 pr-3" :class="{ 'md:w-3/5': !locationOnly }">
					<h2
						v-if="job.name"
						class="text-base max-w-md leading-none"
						:class="nameContainsLongWord(job.name) ? 'break-all' : 'break-words'"
					>
						<div v-if="styling !== 'offline'" @click="preventJobRedirect">
							<HokLink
								:to="jobLink(job, isUserApp)"
								styling="typeform"
								link-type="internal"
								class="relative hover:underline hover:text-color-main-hover z-10 align-middle"
								>{{ shortenString(job.name.toString(), 75) }}
							</HokLink>
						</div>
						<span v-else class="text-color-grey">{{ shortenString(job.name.toString(), 75) }}</span>
					</h2>
					<div class="font-light text-sm lg:text-base md:mb-0">
						<div v-if="job.company.alias && styling !== 'offline'" @click="preventJobRedirect">
							<HokLink
								:to="companyLink(job, isUserApp)"
								link-type="internal"
								:class="{ 'text-color-grey': styling === 'offline' }"
								class="relative hover:underline hover:text-color-main-hover z-10"
							>
								{{ job.company.name }}
							</HokLink>
						</div>
						<span v-else-if="job.company.name" class="text-color-grey">{{ job.company.name }}</span>
						<span v-if="showDate(styling, job)" class="text-2xs text-color-grey-medium">
							{{ fromNow(job.internal.publishedDate) }}
						</span>
					</div>
					<div v-if="job.location && job.location.city && locationOnly" class="flex items-center">
						<HokIcon :size="3" name="icon:pin-2" />
						<div class="word-break ml-1 text-xs leading-none">
							<span v-if="styling === 'offline' || isUserApp">{{ job.location.city }}</span>
							<div v-else @click="preventJobRedirect">
								<HokLink
									v-if="attributes && attributes.cityCanonical"
									:to="attributes.cityCanonical"
									link-type="internal"
									class="relative hover:underline z-10 text-color-text"
									>{{ job.location.city }}</HokLink
								>
								<span
									v-else
									role="link"
									class="relative hover:underline z-10 cursor-pointer"
									@click="openInvisibleLink(cityLink(job))"
									>{{ job.location.city }}</span
								>
							</div>
							<span v-if="attributes && attributes.distanceKm && attributes.distanceKm > 0">{{
								`(${attributes.distanceKm} km entfernt)`
							}}</span>
						</div>
					</div>
				</div>
				<div
					class="w-full md:w-2/5 font-light text-xs md:text-sm lg:text-base"
					:class="{ 'text-color-grey': styling === 'offline' }"
				>
					<div v-if="!locationOnly" class="flex mb-1 sm:mb-2">
						<div v-if="jobTypes && jobTypes.length" class="flex items-center mb-1">
							<HokIcon :size="4" name="icon:time" class="mr-1" />
							<template v-if="styling === 'offline' || isUserApp">
								<div v-for="(type, index) in jobTypes" :key="type" class="word-break">
									{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
								</div>
							</template>
							<template v-else>
								<div v-for="(type, index) in jobTypes" :key="type" @click="preventJobRedirect">
									<span
										role="link"
										class="word-break relative cursor-pointer hover:underline z-10"
										@click="openInvisibleLink(jobTypeLink(type))"
									>
										{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
									</span>
								</div>
							</template>
							<span v-if="job && job.types && job.types.length > jobTypes.length">,...</span>
						</div>

						<div v-if="job.location && job.location.city" class="flex items-center">
							<HokIcon :size="4" name="icon:pin-2" />
							<div class="word-break ml-1">
								<span v-if="styling === 'offline' || isUserApp">{{ job.location.city }}</span>
								<div v-else @click="preventJobRedirect">
									<HokLink
										v-if="attributes && attributes.cityCanonical"
										:to="attributes.cityCanonical"
										link-type="internal"
										class="relative hover:underline z-10 text-color-text"
										>{{ job.location.city }}</HokLink
									>
									<span
										v-else
										role="link"
										class="relative hover:underline z-10 cursor-pointer"
										@click="openInvisibleLink(cityLink(job))"
										>{{ job.location.city }}</span
									>
								</div>
								<span v-if="attributes && attributes.distanceKm && attributes.distanceKm > 0">{{
									`(${attributes.distanceKm} km entfernt)`
								}}</span>
							</div>
						</div>
					</div>
					<div v-if="job.wage && !locationOnly" class="w-full flex items-center">
						<HokIcon :size="4" name="icon:euro" />
						<div class="word-break ml-1">
							{{ shortenString(job.wage.toString(), 40) }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div
				v-if="!leanList"
				class="flex-none flex flex-col items-start max-w-[72px]"
				:class="showDescription ? 'w-1/12' : 'w-1/6'"
			>
				<HokBgImg
					v-if="job.company && job.company.logos"
					:source="job.company.logos"
					:alt="job.company.name"
					class="border border-color-grey-light bg-no-repeat bg-center bg-color-white"
					:class="showDescription ? 'h-14 w-14' : 'h-12 w-12'"
					:bg-cover="false"
					:style="styling === 'offline' ? 'filter: grayscale(1);' : ''"
				/>
				<div
					v-else
					class="flex justify-center items-center border border-color-grey-light bg-color-white"
					:class="showDescription ? 'h-14 w-14' : 'h-12 w-12'"
				>
					<HokBgImg
						source="/shared-components-nuxt3/images/logo-placeholder.png"
						class="flex justify-center items-center"
						:class="showDescription ? 'h-12 w-12' : 'h-10 w-10'"
					>
						<p v-if="job?.company?.name" class="mb-0 uppercase text-3xl">
							{{ job.company.name.charAt(0) }}
						</p>
					</HokBgImg>
				</div>
			</div>
			<div
				class="flex-col text-color-text"
				:class="showDescription && !leanList ? 'w-3/12' : 'flex-1'"
			>
				<h2
					v-if="job.name"
					class="font-bold text-sm sm:text-base mb-1 sm:mb-2 max-w-xs leading-none"
					:class="nameContainsLongWord(job.name) ? 'break-all' : 'break-words'"
				>
					<span
						v-if="unseenJob(lastViewTime, job)"
						class="text-color-white bg-color-blue px-2 rounded-full font-normal text-xs sm:text-sm align-middle"
						>NEU</span
					>
					<div v-if="styling !== 'offline'" @click="preventJobRedirect">
						<HokLink
							:to="jobLink(job, isUserApp)"
							styling="typeform"
							link-type="internal"
							class="text-start hover:underline hover:text-color-main-hover z-10 pb-2 leading-none"
							>{{ shortenString(job.name.toString(), 75) }}
						</HokLink>
					</div>

					<span v-else class="text-color-grey leading-none">{{
						shortenString(job.name.toString(), 75)
					}}</span>
				</h2>
				<div class="text-xs sm:text-sm mb-2">
					<div v-if="job.company.alias && styling !== 'offline'" @click="preventJobRedirect">
						<HokLink
							:to="companyLink(job, isUserApp)"
							link-type="internal"
							class="relative hover:underline z-10 text-color-text"
							data-cy="companyName"
						>
							{{ job.company.name }}
						</HokLink>
					</div>
					<span v-else-if="job.company.name" class="text-color-text">{{ job.company.name }}</span>
					<span
						v-if="showDate(styling, job) && leanList"
						class="mt-1 text-2xs text-color-grey-medium block"
					>
						{{ fromNow(job.internal.publishedDate) }}
					</span>
				</div>
				<div
					v-if="!showDescription && !leanList"
					class="flex flex-col text-2xs sm:text-xs md:text-sm flex-none"
				>
					<div v-if="jobTypes && jobTypes.length" class="flex items-center mb-1">
						<HokIcon :size="4" name="icon:time" class="mr-1" />
						<template v-if="styling === 'offline' || isUserApp">
							<div v-for="(type, index) in jobTypes" :key="type" class="word-break ml-1">
								{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
							</div>
						</template>
						<template v-else>
							<div v-for="(type, index) in jobTypes" :key="type" @click="preventJobRedirect">
								<span
									role="link"
									class="word-break space-x-1 relative cursor-pointer hover:underline z-10"
									@click="openInvisibleLink(jobTypeLink(type))"
								>
									{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
								</span>
							</div>
						</template>
						<span v-if="job && job.types && job.types.length > jobTypes.length">,...</span>
					</div>
					<div v-if="job.location && job.location.city" class="flex items-center mb-1">
						<HokIcon :size="4" name="icon:pin-2" />
						<div class="ml-1 word-break">
							<span v-if="styling === 'offline' || isUserApp">{{ job.location.city }}</span>
							<div v-else @click="preventJobRedirect">
								<HokLink
									v-if="attributes && attributes.cityCanonical"
									:to="attributes.cityCanonical"
									link-type="internal"
									class="relative hover:underline z-10 text-color-text"
									>{{ job.location.city }}</HokLink
								>
								<span
									v-else
									role="link"
									class="relative hover:underline z-10 cursor-pointer"
									@click="openInvisibleLink(cityLink(job))"
									>{{ job.location.city }}</span
								>
							</div>
							<span v-if="attributes && attributes.distanceKm && attributes.distanceKm > 0">{{
								`(${attributes.distanceKm} km entfernt)`
							}}</span>
						</div>
					</div>
					<div v-if="job.wage && job.wage.trim().length > 0" class="flex items-center mb-1">
						<HokIcon :size="4" name="icon:euro" />
						<div class="ml-1 word-break">
							{{ shortenString(job.wage.toString(), 40) }}
						</div>
					</div>
				</div>
				<div
					v-if="job.activeSourcingConversationId"
					class="mt-3 rounded bg-color-coral text-color-white px-1.5 py-1 text-xs leading-none w-fit"
				>
					Interesse an dir
				</div>
				<div v-else-if="showTags && tagName" class="flex">
					<div
						class="mt-2 mb-1 rounded bg-color-grey-lightest text-color-text px-1.5 py-1 text-xs leading-none"
					>
						{{ mapTag(tagName) }}
					</div>
				</div>
				<span v-if="showDate(styling, job) && !leanList" class="text-2xs text-color-grey-medium">
					{{ fromNow(job.internal.publishedDate) }}
				</span>
			</div>
			<template v-if="showDescription || leanList">
				<div class="pl-5 w-7/12 hidden md:flex flex-col mr-2">
					<div :class="{ 'mb-6': !leanList }">
						<div v-if="jobTypes && jobTypes.length" class="flex items-center mb-1">
							<HokIcon :size="4" name="icon:time" class="mr-1" />
							<template v-if="styling === 'offline' || isUserApp">
								<div v-for="(type, index) in jobTypes" :key="type" class="word-break ml-1">
									{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
								</div>
							</template>
							<template v-else>
								<div v-for="(type, index) in jobTypes" :key="type" @click="preventJobRedirect">
									<span
										role="link"
										class="word-break space-x-1 relative cursor-pointer hover:underline z-10"
										@click="openInvisibleLink(jobTypeLink(type))"
									>
										{{ type }}<span v-if="index !== jobTypes.length - 1" class="mr-1">, </span>
									</span>
								</div>
							</template>
							<span v-if="job && job.types && job.types.length > jobTypes.length">,...</span>
						</div>
						<div v-if="job.location && job.location.city" class="flex items-center mb-1">
							<HokIcon :size="4" name="icon:pin-2" />
							<div class="ml-1 word-break">
								<span v-if="styling === 'offline' || isUserApp">{{ job.location.city }}</span>
								<div v-else @click="preventJobRedirect">
									<HokLink
										v-if="attributes && attributes.cityCanonical"
										:to="attributes.cityCanonical"
										link-type="internal"
										class="relative hover:underline z-10 text-color-text"
										>{{ job.location.city }}</HokLink
									>
									<span
										v-else
										role="link"
										class="relative hover:underline z-10 cursor-pointer"
										@click="openInvisibleLink(cityLink(job))"
										>{{ job.location.city }}</span
									>
								</div>
								<span v-if="attributes && attributes.distanceKm && attributes.distanceKm > 0">{{
									`(${attributes.distanceKm} km entfernt)`
								}}</span>
							</div>
						</div>
						<div v-if="job.wage && job.wage.trim().length > 0" class="flex items-center mb-1">
							<HokIcon :size="4" name="icon:euro" />
							<div class="ml-1 word-break">
								{{ shortenString(job.wage.toString(), 40) }}
							</div>
						</div>
					</div>
					<template v-if="!leanList">
						<div v-if="hasRequiredFields(job)">
							<template v-for="field in job.requiredFields">
								<p
									v-if="field.type === 'EDUCATIONLEVEL'"
									:key="field.id"
									class="mb-1 text-xs lg:text-sm"
								>
									<span class="font-bold">Erforderliche Ausbildung: </span>{{ field['level-text'] }}
								</p>
								<p
									v-if="field.type === 'JOBLEVEL' && field.hasOwnProperty('mainfield-text')"
									:key="field.id"
									class="mb-0 text-xs lg:text-sm"
								>
									<span class="font-bold">Erfahrung: </span>
									<span v-if="field.yearsexperience > 0" class="cate">
										{{ field.yearsexperience
										}}{{ field.yearsexperience === 1 ? ' Jahr ' : ' Jahre '
										}}{{ ` als ${field['mainfield-text']}` }}
									</span>
									<span v-else> {{ field['mainfield-text'] }} von Vorteil </span>
								</p>
							</template>
						</div>
						<div v-if="job.description" class="text-xs lg:text-sm">
							<br v-if="hasRequiredFields(job)" />
							<p class="hidden md:block mb-2 break-words">
								{{ job.description }}
							</p>
						</div>
					</template>
				</div>
			</template>
			<div>
				<HokIcon
					v-if="styling !== 'offline'"
					:name="`${jobIsSaved ? 'icon:bookmark' : 'icon:bookmark-outline'}`"
					:color="'main'"
					pointer
					class="relative z-10"
					@click.stop.passive="$emit('save-job', job)"
				/>
			</div>
		</template>
		<HokLink
			v-if="styling !== 'offline'"
			class="post-link__read-more"
			:to="jobLink(job, isUserApp)"
			link-type="internal"
		/>
	</li>
</template>

<script lang="ts">
import type { IAPIJobPreviewForUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { fromNow } from '../helpers/datehelpers/from-now';
import { findLongestWord, shortenString } from '../helpers/stringhelper';
import { getAlternateUrls } from '../helpers/metadata';

export default defineComponent({
	name: 'JobListItemExperimental',
	emits: ['prevent-job-redirect'],
	computed: {
		topLevelDomain(): string | undefined {
			return this.$nuxt?.$userRootStore?.topLevelDomain;
		},
		host(): string | undefined {
			return this.$nuxt?.$userRootStore?.host;
		},
		jobTypes(): string[] | undefined {
			return this.job?.types?.slice(0, 2);
		}
	},
	methods: {
		shortenString,
		fromNow,
		nameContainsLongWord(name: string, limit = 35): boolean {
			const longestWord = findLongestWord(name);
			return longestWord.length > limit;
		},
		companyLink(job: IAPIJobPreviewForUser, isUserApp: boolean): string {
			let url = `/c/${job.company.alias}`;
			if (isUserApp) {
				url = `/pwa/c/${job.company._id}`;
			} else if (job.internal.region && job.internal.region !== this.topLevelDomain && this.host) {
				return getAlternateUrls(this.host, [job.internal.region])[0].href + url;
			}
			return url;
		},
		jobLink(job: IAPIJobPreviewForUser, isUserApp: boolean): string {
			let url = `/job/${job.jobNr}`;
			if (isUserApp) {
				url = `/pwa/job/${job.jobNr}`;
			} else if (job.internal.region && job.internal.region !== this.topLevelDomain && this.host) {
				return getAlternateUrls(this.host, [job.internal.region])[0].href + url;
			}
			return url;
		},
		cityLink(job: IAPIJobPreviewForUser): string {
			return `/jobs/search?city=${job.location.city}`;
		},
		jobTypeLink(type: string): string {
			return `/jobs/search?filters=jobtype-${type}`;
		},
		hasRequiredFields(job: IAPIJobPreviewForUser): boolean {
			let reqAv = false;
			if (job && job.requiredFields) {
				job.requiredFields.forEach(rqf => {
					if ((rqf as any).type === 'EDUCATIONLEVEL' || (rqf as any).type === 'JOBLEVEL') {
						reqAv = true;
					}
				});
			}
			return reqAv;
		},
		mapTag(tag: string): string {
			switch (tag) {
				// case 'finalcountdown':
				// 	return 'In Kürze offline';
				case 'newjob':
					return 'Neuer Job';
				case 'popularjob':
					return 'Beliebter Job';
				case 'nocv':
					return 'Ohne Lebenslauf bewerben';
				// case 'nopic':
				// 	return 'Bewerbung ohne Foto';
				case 'nomotivation':
					return 'Keine Motivationsabfrage';
				case 'nodocument':
					return 'Einfache Bewerbung';
				// case 'nobirthday':
				// 	return 'Keine Altersabfrage';
				// case 'nosalary':
				// 	return 'Keine Gehaltsabfrage';
				// case 'nodistancehome':
				// 	return 'Keine Wohnort Abfrage';

				// TODO: Falls Featureset socialmedia || socialmediapremium -> TAG: "Dringend Gesucht"
				default:
					return '';
			}
		},
		showDate(styling: string, job: IAPIJobPreviewForUser): Date | undefined | false {
			return styling !== 'offline' && job.internal && job.internal.publishedDate;
		},
		preventJobRedirect() {
			// prevent redirect to jobpage when a link is clicked
			this.$emit('prevent-job-redirect');
		},
		unseenJob(lastSeen: Date, job: IAPIJobPreviewForUser): boolean {
			if (lastSeen && job?.internal?.publishedDate) {
				const date1 = new Date(lastSeen);
				const date2 = new Date(job.internal.publishedDate);
				return date1 < date2;
			}
			return false;
		},
		openInvisibleLink(link) {
			window.location.href = link;
		}
	},
	props: {
		isUserApp: { type: Boolean, default: true },
		attributes: { type: Object, required: false, default: () => null },
		job: { type: Object as PropType<IAPIJobPreviewForUser>, required: true },
		debug: { type: Object, required: false, default: () => null },
		enableDebug: { type: Boolean, default: false },
		jobIsSaved: { type: Boolean, default: false },
		styling: {
			type: String,
			default: 'live',
			validator: (value: string) => ['live', 'offline'].includes(value)
		},
		tagName: { type: String, default: '' },
		showTags: { type: Boolean, default: false },
		showDescription: { type: Boolean, default: true },
		minify: { type: Boolean, default: false },
		leanList: { type: Boolean, default: false },
		locationOnly: { type: Boolean, default: false },
		lastViewTime: { type: Date, default: null }
	}
});
</script>

<style scoped lang="scss">
.post-link {
	/* Position any links in the post excerpt at the top of the `z-index` stack. */
	/* leave this empty class for now due to css specificity  */
}

.post-link__read-more {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	/* Needs a heightened specificity to trump `.post-link a`.
         * Stack it under all other links in the post text. */

	position: absolute;
	z-index: 0;
}

.max-w {
	max-width: 260px;
	@media (min-width: 768px) {
		max-width: 320px;
	}
}
</style>
